/**
 * App
*/

import $ from "jquery";
import navbar from "Components/navbar";
import AOS from "../../node_modules/aos/dist/aos";
import LocomotiveScroll from 'locomotive-scroll';


document.addEventListener('DOMContentLoaded', function () {
    window.$ = $;

    AOS.init({});
    navbar();

    setTimeout(() => {
        const scroller = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: true
        })

        scroller.on('scroll', (args) => {
            // Get all current elements : args.currentElements
            if(typeof args.currentElements['learn-more'] === 'object') {
                // const progress = scroll.y / limit.y * 2000
                const bar = document.querySelector('.progress-bar');
                let progress = args.currentElements['learn-more'].progress * 1500;
                bar.style.height = `${progress}px`
            }
        });
    }, 500);
});